<template>
  <div class="summary-page-container" :class="`lesson-${lessonNo}`"></div>
</template>

<script>
export default {
  props: {
    lessonNo: {
      type: Number,
      require: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.lesson-1 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-01.svg") no-repeat;
}
.lesson-3 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-03.svg") no-repeat;
}
.lesson-5 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-05.svg") no-repeat;
}
.lesson-9 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-09.svg") no-repeat;
}
.lesson-11 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-11.svg") no-repeat;
}
.lesson-13 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-13.svg") no-repeat;
}
.lesson-17 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-17.svg") no-repeat;
}
.lesson-19 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-19.svg") no-repeat;
}
.lesson-21 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-21.svg") no-repeat;
}
.lesson-25 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-25.svg") no-repeat;
}
.lesson-27 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-27.svg") no-repeat;
}
.lesson-29 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-29.svg") no-repeat;
}
.lesson-33 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-33.svg") no-repeat;
}
.lesson-35 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-35.svg") no-repeat;
}
.lesson-37 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-37.svg") no-repeat;
}
.lesson-41 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-41.svg") no-repeat;
}
.lesson-43 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-43.svg") no-repeat;
}
.lesson-45 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-45.svg") no-repeat;
}
.lesson-49 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-49.svg") no-repeat;
}
.lesson-51 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-51.svg") no-repeat;
}
.lesson-53 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-53.svg") no-repeat;
}
.lesson-57 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-57.svg") no-repeat;
}
.lesson-59 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-59.svg") no-repeat;
}
.lesson-61 {
  background: #e0e9f7
    url("../../../../assets/img/20-Review/review-l1-lesson-61.svg") no-repeat;
}
.summary-page-container {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  border-radius: 56px;
}
</style>
